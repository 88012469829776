import React, { createContext, useCallback, useMemo, useState } from 'react';

export const AddLoggerContext = createContext(null);

export const AddLoggerContextWrapper = ({ children, maxStep = 3 }) => {
    const [curStep, setCurStep] = useState<number>(1);
    const [asset, setAsset] = useState({ number: null });
    const [logger, setLogger] = useState({ number: null });
    const [photos, setPhotos] = useState([]);

    const nextStep = useCallback(() => {
        if (curStep >= maxStep) return;
        setCurStep(curStep + 1);
    }, [curStep, maxStep, setCurStep]);

    const prevStep = useCallback(() => {
        if (curStep <= 1) return;
        setCurStep(curStep - 1);
    }, [curStep, setCurStep]);

    const value = useMemo(() => ({
        stepper: { curStep, setCurStep, nextStep, prevStep, maxStep },
        asset,
        setAsset,
        logger,
        setLogger,
        photos,
        setPhotos,
    }), [curStep, setCurStep, asset, setAsset, logger, setLogger, photos, setPhotos]);

    return (
        <AddLoggerContext.Provider value={value}>
            {children}
        </AddLoggerContext.Provider>
    );
};
