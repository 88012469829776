import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { ASSET_TYPE_CODES, INSTANCE_TYPES } from '@src/constants';
import { useTranslation } from 'react-i18next';
import { font, theme } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';

export const Description = ({ instanceType, assetTypeCode }) => {
    const { t } = useTranslation();
    const { isLandscape, hp } = useScreen();

    const getMessage = () => {
        if (instanceType === INSTANCE_TYPES.LOGGER) {
            return t('REMOVE_LOGGER.LOGGER_NOT_PAIRED');
        } else if (assetTypeCode === ASSET_TYPE_CODES.PALLET) {
            return t('REMOVE_LOGGER.PALLET_NOT_PAIRED');
        } else {
            return t('REMOVE_LOGGER.CONTAINER_NOT_PAIRED');
        }
    };

    const styles = StyleSheet.create({
        devider: {
            backgroundColor: theme.palette.common.firstShadeOfGray,
            height: 1,
            marginBottom: 40,
            marginTop: 80,
            width: '100%',
        },
        text: {
            ...font.normal,
            color: theme.palette.primary.darkBlue,
            fontSize: 24,
            letterSpacing: 0.18,
            lineHeight: 24,
            textAlign: 'center',
            ...(isLandscape && {
                fontSize: hp('3.5%'),
                lineHeight: hp('4.4%'),
            }),
        },
        wrapper: {
            ...(isLandscape && {
                marginBottom: hp('4%'),
            }),
        },
    });

    return (
        <View style={styles.wrapper}>
            {!isLandscape && <View style={styles.devider} />}
            <View>
                <Text style={styles.text}>{getMessage()}</Text>
            </View>
        </View>
    );
};
