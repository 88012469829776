import * as React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { useScreen } from '@src/hooks/useScreen';
import { useNavigation } from '@react-navigation/native';
import Ionicons from '@expo/vector-icons/Ionicons';
import { theme } from '@src/styles';

export const HeaderRight = () => {
    const navigation = useNavigation();
    const { isLandscape, wp, hp } = useScreen();

    const styles = StyleSheet.create({
        wrapper: {
            marginRight: isLandscape ? wp('2.1%') : 15,
            padding: 2,
        },
    });

    return (
        <TouchableOpacity
            style={styles.wrapper}
            onPress={() => navigation.toggleDrawer()}
        >
            <Ionicons
                name="menu-sharp"
                size={isLandscape ? hp('5%') : 24}
                color={theme.palette.common.white}
            />
        </TouchableOpacity>
    );
};
