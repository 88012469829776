const skycellPrimaryPalette = {
    30: '#E0F7FF',
    50: '#C3E9F7',
    100: '#AADBEC',
    200: '#8BD1E8',
    300: '#6FCAE8',
    400: '#61C6E9',
    500: '#57B1CF',
    600: '#4C9BB5',
    700: '#4790A8',
    800: '#2C5969',
    main: '#61C6E9',
    light: '#00739A',
    dark: '#4C9BB5',
    deepBlue: '#009CCF',
    darkBlue: '#00739A',
    contrastText: '#393939',
};

const skycellSecondaryPalette = {
    50: '#F7F7F7',
    100: '#EFEFEF',
    200: '#DFDFDF',
    300: '#D7D7D7',
    400: '#B7B7B7',
    500: '#939393',
    600: '#747474',
    700: '#6B6B6B',
    800: '#393939',
    900: '#191919',
    main: '#939393',
    light: '#D7D7D7',
    dark: '#393939',
    gray: '#7C7C7C',
};

export const theme = {
    palette: {
        primary: skycellPrimaryPalette,
        secondary: skycellSecondaryPalette,
        common: {
            white: '#FFFFFF',
            black: '#000000',
            purple: '#CF3B8A',
            yellow: '#EDAE49',
            lightYellow: '#EEE172',
            beige: '#efe0c1',
            green: '#17921F',
            red: '#D44848',
            yellowText: '#747474',
            lightYellowText: '#747474',
            greenText: '#747474',
            redText: '#747474',
            contrastWhite: '#FFF',
            contrastWhite50: '#FFF',
            contrastBlue: '#FFF',
            menuTextWhite: '#FFF',
            titleBlackText: '#000',
            primaryHighlight30: '#E0F7FF',
            contrastGray600: '#747474',
            dividerColor: '#939393',
            firstShadeOfGray: '#b7b7b7',
            secondaryGray: '#00000066',
            secondShadeOfGray: '#dfdfdf',
            thirdShadeOfGray: '#a7a7a7',
            fourthShadeOfGray: '#747474',
            borderGray: '#545F71',
            dropdownGray: '#5F6368',
            blackTransparent: '#00000070',
            transparent: 'transparent',
            cameraQRCode: '#fffa30',
            constants: {
                white: '#FFF',
                gray900: '#191919',
            },
            notification: {
                successBg: '#0B953B',
                errorBg: '#D44848',
                warningBg: '#FFD426',
            },
            sidebar: {
                closeBg: 'rgba(235, 235, 245, 0.60)',
                borderBottom: 'rgba(0, 0, 0, 0.12)',
                userName: 'rgba(0, 0, 0, 0.87)',
            },
            sliderInActive: 'rgba(255, 255, 255, 0.70)',
        },
        action: {
            selected: '#6FCAE8',
        },
        info: {
            main: '#61C6E9',
        },
        success: {
            main: '#43A047',
        },
        error: {
            main: '#FF0000',
        },
    },
};
