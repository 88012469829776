import React, { useEffect, useState, useContext, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { STATUSES, Status } from '@src/components/shared-components/Status';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { useTranslation } from 'react-i18next';
import { INSTANCE_TYPES } from '@src/constants';
import { getStatus } from '@src/lib';
import { SpinnerScreen } from '@src/components/SpinnerScreen';
import { useNotification } from '@src/hooks/useNotification';
import { Layout } from '@src/components/Layout';
import { ConfirmModal } from '@src/RemoveLogger/Screens/CheckCode/ConfirmModal';
import { Container } from '@src/components/Layout/Container';
import { Number } from '@src/components/Number';
import { useScreen } from '@src/hooks/useScreen';
import { ADD_LOGGER_SCREENS } from '../constant';

const nextScreenAsset = ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE;
const nextScreenLogger = ADD_LOGGER_SCREENS.PAIRING_CONFIRM;
const backScreenAsset = ADD_LOGGER_SCREENS.ENTER_ASSET_CODE;
const backScreenLogger = ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE;

type Props = {
    // TODO: fix any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigation: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    route: any,
    type: 'asset' | 'logger',
};

const CheckAssetOrLogger = ({ route, navigation, type = 'asset' }: Props) => {
    const { fromScreen } = route.params || {};
    const { t } = useTranslation();
    const { showSuccess } = useNotification();
    const { isLandscape, wp, hp } = useScreen();
    const { asset, setAsset, logger, setLogger, stepper } = useContext(AddLoggerContext);
    const [status, setStatus] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { checkPairingByAsset, checkPairingByLogger } = useBackendEndpoints().requests;

    const instance = useMemo(() => (type === INSTANCE_TYPES.ASSET ? asset : logger), [type, asset, logger]);
    const setInstance = useMemo(
        () => (type === INSTANCE_TYPES.ASSET ? setAsset : setLogger),
        [type, setAsset, setLogger],
    );
    const backScreen = useMemo(() => (type === INSTANCE_TYPES.ASSET ? backScreenAsset : backScreenLogger), [type]);
    const nextScreen = useMemo(() => (type === INSTANCE_TYPES.ASSET ? nextScreenAsset : nextScreenLogger), [type]);
    const checkPairing = useMemo(
        () => (type === INSTANCE_TYPES.ASSET ? checkPairingByAsset : checkPairingByLogger),
        [type, checkPairingByAsset, checkPairingByLogger],
    );

    const checkInstance = async () => {
        if (!instance.number) return;
        const result = await checkPairing(instance.number);

        if (result && result.data) {
            const { data } = result;

            setInstance({ ...instance, ...data });
            getStatus({ type, data, setStatus });
        } else {
            setStatus(STATUSES.ERROR);
        }
    };

    const tryAgain = () => {
        if (!fromScreen.includes('SCAN')) {
            navigation.goBack();
            return;
        }

        navigation.reset({
            index: 0, routes: [{ name: fromScreen || backScreen }],
        });
    };

    useEffect(() => {
        checkInstance();
    }, []);

    useEffect(() => {
        if (status === STATUSES.IDENTIFIED) {
            stepper.nextStep();
            navigation.navigate(nextScreen);
            showSuccess(`${instance.number} ${t('ADD_LOGGER.WAS_IDENTIFIED')}`);
        }
    }, [status]);

    const styles = StyleSheet.create({
        notFoundContainerLeft: {
            ...(isLandscape && {
                width: '52%',
            }),
        },
        notFoundContainerRight: {
            ...(isLandscape && {
                width: '46%',
            }),
        },
        notFoundWrapper: {
            ...(isLandscape && {
                paddingTop: hp('7.3%'),
            }),
        },
        pairMarginLeft: {
            ...(isLandscape && {
                marginLeft: wp('5%'),
            }),
        },
        pairedContainerLeft: {
            ...(isLandscape && {
                width: '52%',
            }),
        },
        pairedContainerRight: {
            ...(isLandscape && {
                paddingTop: hp('9%'),
                width: '44%',
            }),
        },
    });

    if (status === STATUSES.NOT_FOUND) {
        return (
            <Layout verticalAlign="center" wrapperStyle={styles.notFoundWrapper}>
                <Container style={styles.notFoundContainerLeft} isPaddingTop>
                    <Status status={STATUSES.NOT_FOUND} instanceType={type} />
                    <Number>{instance.number}</Number>
                </Container>

                <Container style={styles.notFoundContainerRight}>
                    {type === INSTANCE_TYPES.ASSET
                        ? (
                            <ButtonsBlock
                                primaryBtnText={t('ADD_LOGGER.ADD_NEW_ASSET')}
                                primaryBtnOnPress={() => navigation.navigate(ADD_LOGGER_SCREENS.ADD_NEW_ASSET)}
                                secondaryBtnText={t('COMMON.TRY_AGAIN')}
                                secondaryBtnOnPress={tryAgain}
                            />
                        )
                        : (
                            <ButtonsBlock
                                primaryBtnText={t('COMMON.TRY_AGAIN')}
                                primaryBtnOnPress={tryAgain}
                            />
                        )}
                </Container>
            </Layout>
        );
    }

    if (status === STATUSES.PAIRED) {
        return (
            <Layout
                isWrapperNoPadding={isLandscape}
                verticalAlign="center"
            >
                <Container style={styles.pairedContainerLeft} isPaddingTop>
                    <Status
                        status={STATUSES.PAIRED}
                        assetNumber={instance.assetNumber}
                        loggerNumber={instance.loggerNumber}
                        assetTypeCode={asset.assetTypeCode}
                        pairStyle={styles.pairMarginLeft}
                    />

                    <ConfirmModal
                        isVisible={isModalVisible}
                        onClose={() => setIsModalVisible(false)}
                        type={type}
                        assetTypeCode={asset.assetTypeCode}
                        navigation={navigation}
                        context={AddLoggerContext}
                        successScreen={
                            type === INSTANCE_TYPES.ASSET
                                ? ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE
                                : ADD_LOGGER_SCREENS.PAIRING_CONFIRM
                        }
                        successMessage={t(
                            'ADD_LOGGER.REMOVAL_SUCCESS',
                            { number: type === INSTANCE_TYPES.ASSET ? instance.assetNumber : instance.loggerNumber },
                        )}
                    />
                </Container>

                <Container style={styles.pairedContainerRight}>
                    <ButtonsBlock
                        primaryBtnText={t('COMMON.REMOVE')}
                        primaryBtnOnPress={() => setIsModalVisible(true)}
                        secondaryBtnText={t('COMMON.BACK')}
                        secondaryBtnOnPress={tryAgain}
                    />
                </Container>
            </Layout>
        );
    }

    if (status === STATUSES.ERROR) {
        return (
            <Layout verticalAlign="center">
                <Container isPaddingTop>
                    <Status status={STATUSES.ERROR} />
                </Container>

                <Container>
                    {isLandscape ? (
                        <ButtonsBlock
                            primaryBtnText={t('COMMON.TRY_AGAIN')}
                            primaryBtnOnPress={tryAgain}
                        />
                    ) : (
                        <ButtonsBlock
                            secondaryBtnText={t('COMMON.TRY_AGAIN')}
                            secondaryBtnOnPress={tryAgain}
                        />
                    )}
                </Container>
            </Layout>
        );
    }

    return (
        <SpinnerScreen />
    );
};

export const CheckAsset = (props) => (
    <CheckAssetOrLogger type="asset" {...props} />
);

export const CheckLogger = (props) => (
    <CheckAssetOrLogger type="logger" {...props} />
);
