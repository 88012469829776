import React, { useContext, useEffect } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { StoreContext } from '@src/Contexts/StoreContext';
import { paddingBottom, paddingLeft, paddingRight, theme } from '@src/styles';
import { SCREEN_NAMES } from '@src/Root/constant';
import { Button } from '@src/components/shared-components/Button';
import { useTranslation } from 'react-i18next';
import { useScreen } from '@src/hooks/useScreen';

export const SuccessScreen = ({ navigation, children }) => {
    const { t } = useTranslation();
    const { isLandscape, wp, hp } = useScreen();
    const { layout, setLayout } = useContext(StoreContext);

    useEffect(() => {
        setLayout({ ...layout, isFullScreen: true });
    }, []);

    const onPress = () => {
        setLayout({ ...layout, isFullScreen: false });
        navigation.reset({ index: 0, routes: [{ name: SCREEN_NAMES.ACTIONS }] });
    };

    const styles = StyleSheet.create({
        btn: {
            ...(isLandscape && {
                marginTop: hp('4.2%'),
            }),
        },
        btnWrapper: {
            flex: 1,
            justifyContent: 'flex-end',
            paddingLeft,
            paddingRight,
            ...(isLandscape && {
                paddingLeft: 0,
                paddingRight: 0,
                flexGrow: 1,
            }),
        },
        container: {
            backgroundColor: theme.palette.primary.deepBlue,
            flex: 1,
            ...(isLandscape && {
                alignItems: 'center',
                justifyContent: 'center',
            }),
        },
        image: {
            height: 202,
            width: 202,
            ...(isLandscape && {
                height: wp('18%'),
                width: wp('18%'),
            }),
        },
        imageWrapper: {
            alignItems: 'center',
            flex: 1,
            flexGrow: 2,
            justifyContent: 'center',
            ...(isLandscape && {
                flexGrow: 1,
            }),
        },
        wrapper: {
            flex: 1,
            paddingBottom,
            width: wp('100%'),
            ...(isLandscape && {
                width: wp('80%'),
                paddingBottom: 0,
                flexDirection: 'row',
                alignItems: 'center',
            }),
        },
        wrapperChildren: {
            paddingLeft,
            paddingRight,
        },
    });

    return (
        <View style={styles.container}>
            <View style={styles.wrapper}>
                <View style={styles.imageWrapper}>
                    <Image
                        style={styles.image}
                        source={require('@assets/images/task-alt.png')}
                        resizeMode="contain"
                    />
                </View>

                {!isLandscape && (
                    <View style={styles.wrapperChildren}>
                        {children}
                    </View>
                )}

                <View style={styles.btnWrapper}>
                    {isLandscape && children}

                    <Button
                        onPress={onPress}
                        variant="white"
                        style={styles.btn}
                    >
                        {t('COMMON.MAIN_MENU')}
                    </Button>
                </View>
            </View>
        </View>
    );
};
