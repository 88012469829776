import React, { useContext } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { apiUrl } from '@src/constants/env';
import ReactNativeBlobUtil from 'react-native-blob-util';
import { useTranslation } from 'react-i18next';
import { useScreen } from '@src/hooks/useScreen';
import { paddingLeft, paddingRight } from '@src/styles';
import { Title } from '@src/components/Title';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { dataURLtoFile } from '@src/lib';
import { useJWTToken } from '@src/hooks/useJWTToken';
import { PhotoWithSlides } from './PhotoWithSlides';
import { ADD_LOGGER_SCREENS } from '../constant';
import { TakePictureDescription } from './TakePictureDescription';
import { cameraContainerHeight, cameraWrapperMarginTop, titleMarginBottom } from './constant';

export const TakePictureConfirm = ({ navigation }) => {
    const token = useJWTToken();
    const { t } = useTranslation();
    const { isLandscape, hp } = useScreen();
    const { FlexibleRequest: sendPhotos } = useBackendEndpoints()?.requests || {};
    const { photos, asset } = useContext(AddLoggerContext);

    const confirm = async () => {
        try {
            if (Platform.OS === 'web') {
                const filesPromises = photos.map((photo) => dataURLtoFile(photo.uri));
                const files = await Promise.all(filesPromises);
                const formData = new FormData();

                files.forEach((file, i) => formData.append('files', file, `_${i + 1}.jpg`));

                const result = await sendPhotos(
                    'POST',
                    `/assets/${asset.assetId}/pairing-photos`,
                    formData,
                );

                if (result && result.status === 200) {
                    navigation.navigate(ADD_LOGGER_SCREENS.PAIRING_SUCCESS);
                }
            } else {
                const files = photos.map((photo, i) => ({
                    name: 'files',
                    filename: `_${i + 1}.jpg`,
                    type: 'image/jpg',
                    data: ReactNativeBlobUtil.wrap(photo.uri),
                }));

                const result = await ReactNativeBlobUtil.fetch(
                    'POST',
                    `${apiUrl}/assets/${asset.assetId}/pairing-photos`,
                    {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    files,
                );

                if (result && result.respInfo?.status === 200) {
                    navigation.navigate(ADD_LOGGER_SCREENS.PAIRING_SUCCESS);
                }
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('error:');
            // eslint-disable-next-line no-console
            console.log(error);
        }
    };

    const styles = StyleSheet.create({
        cameraContainer: {
            height: hp(`${cameraContainerHeight}%`),
        },
        containerBottom: {
            flexGrow: 0,
        },
        descriptionWrapper: {
            ...(isLandscape && {
                marginBottom: hp('6.3%'),
            }),
        },
        title: {
            marginBottom: 24,
            marginTop: 48,
            paddingLeft,
            paddingRight,
            ...(isLandscape && {
                marginTop: 0,
                marginBottom: hp(`${titleMarginBottom}%`),
                paddingLeft: 0,
                paddingRight: 0,
                fontSize: hp('3.5%'),
                lineHeight: hp('4.2%'),
            }),
        },
        wrapper: {
            paddingLeft: 0,
            paddingRight: 0,
            ...(isLandscape && {
                marginTop: hp(`${cameraWrapperMarginTop}%`),
            }),
        },
    });

    return (
        <Layout
            isStepperShown={false}
            wrapperStyle={styles.wrapper}
            verticalAlign="center"
        >
            <Container style={styles.cameraContainer}>
                {!isLandscape && (
                    <>
                        <Title style={styles.title}>
                            {t('TAKE_PICTURE.CHECK_PHOTO_QUALITY')}
                        </Title>
                        <TakePictureDescription />
                    </>
                )}
                <PhotoWithSlides />
            </Container>
            <Container style={styles.containerBottom}>
                {isLandscape && (
                    <>
                        <Title style={styles.title}>
                            {t('TAKE_PICTURE.CHECK_PHOTO_QUALITY')}
                        </Title>
                        <TakePictureDescription wrapperStyle={styles.descriptionWrapper} />
                    </>
                )}

                <ButtonsBlock
                    withPadding
                    primaryBtnText={t('TAKE_PICTURE.CONFIRM_PAIRING')}
                    primaryBtnOnPress={confirm}
                    isPrimaryBtnDisabled={!photos?.length}
                />
            </Container>
        </Layout>
    );
};
