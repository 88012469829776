import React, { useContext, useMemo, useState } from 'react';
import { Modal, View, Text, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { useTranslation } from 'react-i18next';
import { font, paddingLeft, paddingRight, theme } from '@src/styles';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { RemoveLoggerContext } from '@src/RemoveLogger/RemoveLoggerContext';
import { ASSET_TYPE_CODES, INSTANCE_TYPES } from '@src/constants';
import { useNotification } from '@src/hooks/useNotification';
import { useScreen } from '@src/hooks/useScreen';
import { Select } from '@src/components/shared-components/Select';
import { REMOVE_LOGGER_SCREENS } from '../constant';

const REMOVE_LOGGER_REASONS = [
    'ASSET_REMOVAL_ULD_NOT_IN_USE',
    'ASSET_REMOVAL_ULD_NEEDS_REPAIR',
    'ASSET_REMOVAL_LOGGER_DAMAGED',
    'ASSET_REMOVAL_LOGGER_BATTERY_LOW',
    'ASSET_REMOVAL_LOGGER_FALLOUT',
    'ASSET_REMOVAL_LOGGER_NUMBER_INCORRECT',
];

export const ConfirmModal = ({
    navigation,
    isVisible,
    onClose,
    type,
    context = RemoveLoggerContext,
    assetTypeCode = '',
    successScreen = '',
    successMessage = '',
}) => {
    const { isLandscape, hp, wp } = useScreen();
    const { t } = useTranslation();
    const { showSuccess } = useNotification();
    const { logger, asset, stepper } = useContext(context);
    const instance = useMemo(() => (type === INSTANCE_TYPES.ASSET ? asset : logger), [type, asset, logger]);
    const { FlexibleRequest: removePairing } = useBackendEndpoints()?.requests || {};
    const [reason, setReason] = useState('');
    const [inputError, setInputError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onChange = (selectedItem) => {
        setReason(selectedItem);
        if (inputError) {
            setInputError('');
        }
    };

    const remove = async () => {
        if (!reason) {
            setInputError(t('COMMON.FIELD_REQUIRED'));
            return;
        }

        setIsLoading(true);

        const result = await removePairing(
            'POST',
            // eslint-disable-next-line max-len
            `/assets/${instance.assetId}/action/remove-logger?loggerNumber=${instance.loggerNumber}&removalReason=${reason}`,
            {},
        );

        if (result && result.status === 200) {
            onClose();
            navigation.navigate(successScreen || REMOVE_LOGGER_SCREENS.REMOVAL_SUCCESS, { type });
            if (successMessage) {
                showSuccess(successMessage);
            }
            stepper.nextStep();
        }

        setIsLoading(false);
    };

    const styles = StyleSheet.create({
        backDrop: {
            backgroundColor: theme.palette.common.blackTransparent,
            height: '100%',
            width: '100%',
        },
        buttonsBlock: {
            paddingTop: 10,
            ...(isLandscape && {
                paddingBottom: hp('2.6%'),
            }),
        },
        dropdownRowTxtStyle: {
            ...font.normal,
            color: theme.palette.common.black,
            fontSize: 14,
            paddingBottom: 5,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 5,
            textAlign: 'left',
            ...(isLandscape && {
                fontSize: hp('3%'),
                lineHeight: hp('3.6%'),
                paddingBottom: hp('1.3%'),
                paddingLeft: hp('1.3%'),
                paddingRight: hp('1.3%'),
                paddingTop: hp('1.3%'),
            }),
        },
        dropdownWrapper: {
            alignItems: 'center',
            flex: 1,
            marginBottom: hp('2.6%'),
        },
        inputError: {
            ...font.normal,
            color: theme.palette.error.main,
            fontSize: 16,
            letterSpacing: 0.15,
            lineHeight: 24,
            marginTop: 16,
        },
        marginTopTablet: {
            ...(isLandscape && {
                marginTop: hp('6.3%'),
            }),
        },
        modalContent: {
            flex: 1,
            paddingLeft,
            paddingRight,
            paddingTop: 35,
            width: '100%',
            ...(isLandscape && {
                width: wp('70%'),
            }),
        },
        modalWrapper: {
            alignItems: 'center',
            backgroundColor: theme.palette.common.white,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            bottom: 0,
            left: wp('0.5%'),
            minHeight: '55%',
            position: 'absolute',
            width: wp('99%'),
            ...(isLandscape && {
                minHeight: '30%',
            }),
        },
        text: {
            ...font.normal,
            color: theme.palette.common.black,
            fontSize: 16,
            letterSpacing: 0.15,
            lineHeight: 18,
            textAlign: 'center',
            ...(isLandscape && {
                fontSize: hp('2.5%'),
                lineHeight: hp('2.5%'),
            }),
        },
        title: {
            ...font.medium,
            color: theme.palette.common.black,
            fontSize: 20,
            letterSpacing: 0.15,
            lineHeight: 24,
            marginBottom: 17,
            textAlign: 'center',
            ...(isLandscape && {
                ...font.normal,
                fontSize: hp('3.5%'),
                lineHeight: hp('3.8%'),
                marginBottom: hp('1.0%'),
            }),
        },
    });

    const assetTypeText = assetTypeCode === ASSET_TYPE_CODES.PALLET
        ? t('COMMON.PALLET')
        : t('COMMON.CONTAINER');

    return (
        <Modal
            animationType="fade"
            visible={isVisible}
            transparent
        >
            <TouchableOpacity
                activeOpacity={1}
                onPress={onClose}
                style={styles.backDrop}
            />
            <View style={styles.modalWrapper}>
                <View style={styles.modalContent}>
                    <Text style={styles.title}>{t('REMOVE_LOGGER.MODAL_TITLE', { assetType: assetTypeText })}</Text>
                    <Text style={styles.text}>{t('REMOVE_LOGGER.MODAL_NO_REVERT')}</Text>
                    <Text style={[styles.text, styles.marginTopTablet]}>{t('REMOVE_LOGGER.MODAL_REASON')}</Text>

                    <View style={styles.dropdownWrapper}>
                        <Select
                            data={REMOVE_LOGGER_REASONS}
                            onSelect={onChange}
                            buttonTextAfterSelection={(selectedItem) => t(`REMOVAL_REASONS.${selectedItem}`)}
                            defaultButtonText={t('COMMON.SELECT')}
                            renderCustomizedRowChild={(item) => {
                                return (
                                    <Text style={styles.dropdownRowTxtStyle}>{t(`REMOVAL_REASONS.${item}`)}</Text>
                                );
                            }}
                        />

                        {inputError && <Text style={styles.inputError}>{inputError}</Text>}
                    </View>

                    <ButtonsBlock
                        primaryBtnText={isLoading
                            ? <ActivityIndicator size="small" color={theme.palette.common.white} />
                            : t('COMMON.REMOVE')}
                        isPrimaryBtnDisabled={isLoading}
                        primaryBtnOnPress={remove}
                        secondaryBtnText={t('COMMON.CANCEL')}
                        secondaryBtnOnPress={onClose}
                        style={styles.buttonsBlock}
                        isDirectionRow
                    />
                </View>
            </View>
        </Modal>
    );
};
