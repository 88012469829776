import React from 'react';
import {
    Text,
    TouchableOpacity,
    StyleSheet,
} from 'react-native';
import { useScreen } from '@src/hooks/useScreen';
import { font, theme } from '@src/styles';

type Props = {
    children: React.ReactNode,
    variant?: 'secondary' | 'primary' | 'danger' | 'white' | 'primary-dark',
    style?: object,
    onPress?: () => void,
    disabled?: boolean,
};

export const Button = ({ children, variant = 'secondary', style, disabled = false, ...rest }: Props) => {
    const { isLandscape, hp } = useScreen();

    const getStyles = () => {
        switch (variant) {
        case 'primary':
            return {
                btn: {
                    backgroundColor: theme.palette.primary.deepBlue,
                    borderColor: theme.palette.primary.deepBlue,
                    borderWidth: 1,
                },
                text: {
                    ...font.medium,
                    color: theme.palette.common.white,
                },
            };
        case 'primary-dark':
            return {
                btn: {
                    backgroundColor: theme.palette.primary.darkBlue,
                    borderWidth: 0,
                },
                text: {
                    color: theme.palette.common.white,
                },
            };
        case 'secondary':
            return {
                btn: {
                    borderColor: theme.palette.primary.darkBlue,
                },
                text: {
                    color: theme.palette.primary.darkBlue,
                },
            };
        case 'danger':
            return {
                btn: {
                    borderColor: '#D44848',
                    borderWidth: 1,
                },
                text: {
                    color: '#D44848',
                },
            };
        case 'white':
            return {
                btn: {
                    borderColor: theme.palette.common.white,
                    borderWidth: 1,
                },
                text: {
                    color: theme.palette.common.white,
                },
            };
        default:
            return {};
        }
    };

    const styles = StyleSheet.create({
        btn: {
            alignItems: 'center',
            backgroundColor: theme.palette.common.transparent,
            borderRadius: 8,
            borderWidth: 1,
            display: 'flex',
            justifyContent: 'center',
            minHeight: 48,
            paddingBottom: 6,
            paddingLeft: 8,
            paddingRight: 6,
            paddingTop: 6,
            width: '100%',
            ...(isLandscape && {
                paddingTop: hp('2.0%'),
                paddingBottom: hp('2.0%'),
            }),
        },
        disabled: {
            backgroundColor: theme.palette.secondary.light,
            borderColor: theme.palette.secondary.light,
        },
        text: {
            ...font.medium,
            fontSize: 14,
            letterSpacing: 1.25,
            textTransform: 'uppercase',
            ...(isLandscape && {
                ...font.normal,
                fontSize: hp('2.5%'),
                lineHeight: hp('2.5%'),
                letterSpacing: 0.18,
            }),
        },
    });

    return (
        <TouchableOpacity
            style={[styles.btn, getStyles().btn, disabled && styles.disabled, style]}
            disabled={disabled}
            {...rest}
        >
            <Text style={[styles.text, getStyles().text]}>
                {children}
            </Text>
        </TouchableOpacity>
    );
};
