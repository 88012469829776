import React, { useContext } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Button } from 'react-native';
import { Camera, CameraType } from 'expo-camera';
import { useTranslation } from 'react-i18next';
import { useScreen } from '@src/hooks/useScreen';
import { paddingLeft, paddingRight, theme } from '@src/styles';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { Title } from '@src/components/Title';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { ADD_LOGGER_SCREENS } from '../constant';
import { TakePictureDescription } from '../TakePictureConfirm/TakePictureDescription';
import {
    cameraContainerHeight,
    cameraWrapperMarginTop,
    titleFontSize,
    titleLineHeight,
    titleMarginBottom,
} from '../TakePictureConfirm/constant';

let camera: Camera;

export const TakePictureInit = ({ navigation }) => {
    const { t } = useTranslation();
    const { isLandscape, hp } = useScreen();
    const { setPhotos } = useContext(AddLoggerContext);
    const [permission, requestPermission] = Camera.useCameraPermissions();

    const skip = () => {
        navigation.navigate(ADD_LOGGER_SCREENS.PAIRING_SUCCESS);
    };

    const takePicture = async () => {
        const photo = await camera.takePictureAsync({ isImageMirror: true });

        setPhotos((prev) => [...prev, photo]);
        navigation.navigate(ADD_LOGGER_SCREENS.TAKE_PICTURE_CONFIRM);
    };

    const btnPhotoOuterSize = 111;
    const btnPhotoInnerDiffSize = 16;

    const styles = StyleSheet.create({
        btnPhoto: {
            borderColor: theme.palette.common.white,
            borderRadius: btnPhotoOuterSize / 2,
            borderWidth: 1,
            bottom: 0,
            height: btnPhotoOuterSize,
            left: '50%',
            position: 'absolute',
            transform: [{ translateX: -btnPhotoOuterSize / 2 }],
            width: btnPhotoOuterSize,
        },
        btnPhotoInner: {
            backgroundColor: theme.palette.common.white,
            borderRadius: btnPhotoOuterSize - btnPhotoInnerDiffSize / 2,
            height: btnPhotoOuterSize - btnPhotoInnerDiffSize,
            left: '50%',
            position: 'absolute',
            top: '50%',
            transform: [
                { translateX: -btnPhotoOuterSize / 2 + btnPhotoInnerDiffSize / 2 },
                { translateY: -btnPhotoOuterSize / 2 + btnPhotoInnerDiffSize / 2 },
            ],
            width: btnPhotoOuterSize - btnPhotoInnerDiffSize,
        },
        btnSkip: {
            bottom: 0,
            left: 0,
            position: 'absolute',

        },
        buttonsContainer: {
            flex: 1,
            margin: 34,
            position: 'relative',
        },
        camera: {
            flex: 1,
        },
        cameraContainer: {
            height: hp(`${cameraContainerHeight}%`),
        },
        cameraWrapper: {
            flex: 1,
        },
        container: {
            flex: 1,
            justifyContent: 'center',
        },
        permissionText: {
            textAlign: 'center',
        },
        text: {
            color: theme.palette.common.white,
            fontSize: 18,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            ...(isLandscape && {
                fontSize: hp('2.5%'),
            }),
        },
        title: {
            fontSize: 24,
            lineHeight: 28,
            marginBottom: 24,
            marginTop: 48,
            paddingLeft,
            paddingRight,
            ...(isLandscape && {
                marginTop: 0,
                marginBottom: hp(`${titleMarginBottom}%`),
                paddingLeft: 0,
                paddingRight: 0,
                fontSize: hp(`${titleFontSize}%`),
                lineHeight: hp(`${titleLineHeight}%`),
            }),
        },
        wrapper: {
            ...(isLandscape && {
                marginTop: hp(`${cameraWrapperMarginTop}%`),
            }),
        },
    });

    if (!permission) {
        // Camera permissions are still loading
        return <View />;
    }

    if (!permission.granted) {
        // Camera permissions are not granted yet
        return (
            <View style={styles.container}>
                <Text style={styles.permissionText}>We need your permission to show the camera</Text>
                <Button onPress={requestPermission} title="grant permission" />
            </View>
        );
    }

    return (
        <Layout
            isFullScreenProp={!isLandscape}
            isStepperShown={false}
            verticalAlign="center"
            wrapperStyle={styles.wrapper}
        >
            <Container style={styles.cameraContainer}>
                {!isLandscape && (
                    <Title style={styles.title}>
                        {t('TAKE_PICTURE.TITLE_INIT_SCREEN')}
                    </Title>
                )}
                <View style={styles.cameraWrapper}>
                    <Camera
                        style={styles.camera}
                        type={CameraType.back}
                        ratio="1:1"
                        ref={(r) => {
                            camera = r;
                        }}
                        pictureSize="1920x1080"
                    >
                        <View style={styles.buttonsContainer}>
                            <TouchableOpacity style={styles.btnSkip} onPress={skip}>
                                <Text style={styles.text}>{t('TAKE_PICTURE.SKIP')}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.btnPhoto} onPress={takePicture}>
                                <View style={styles.btnPhotoInner} />
                            </TouchableOpacity>
                        </View>
                    </Camera>
                </View>
            </Container>
            {isLandscape && (
                <Container>
                    <Title style={styles.title}>
                        {t('TAKE_PICTURE.TITLE_INIT_SCREEN')}
                    </Title>
                    <TakePictureDescription />
                </Container>
            )}
        </Layout>
    );
};
