import React, { useContext } from 'react';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { STATUSES, Status } from '@src/components/shared-components/Status';
import { SuccessScreen } from '@src/components/SuccessScreen';
import { Layout } from '@src/components/Layout';
import { useScreen } from '@src/hooks/useScreen';

export const PairingSuccess = ({ navigation }) => {
    const { isLandscape } = useScreen();
    const { asset, logger } = useContext(AddLoggerContext);

    return (
        <Layout isWrapperNoPadding={isLandscape}>
            <SuccessScreen navigation={navigation}>
                <Status
                    status={STATUSES.PAIRED_SUCCESS}
                    assetNumber={asset.number}
                    loggerNumber={logger.number}
                    assetTypeCode={asset.assetTypeCode}
                />
            </SuccessScreen>
        </Layout>
    );
};
