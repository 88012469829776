import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { theme } from '@src/styles';
import { ErrorScreen } from '@src/components/ErrorScreen';
import { getPageTitle } from '@src/lib';
import { EnterAssetCode } from './EnterAssetCode';
import { ADD_LOGGER_SCREENS } from './constant';
import { ScanAssetCode } from './ScanAssetCode';
import { CheckAsset, CheckLogger } from './CheckAssetOrLogger';
import { EnterLoggerCode } from './EnterLoggerCode';
import { ScanLoggerCode } from './ScanLoggerCode';
import { PairingConfirm } from './PairingConfirm';
import { PairingSuccess } from './PairingSuccess';
import { AddNewAsset } from './AddNewAsset';
import { TakePictureInit } from './TakePictureInit';
import { TakePictureConfirm } from './TakePictureConfirm';

const Stack = createNativeStackNavigator();

export const AddLoggerStack = () => {
    return (
        <Stack.Navigator screenOptions={{
            title: getPageTitle(),
            headerShown: false,
            contentStyle: {
                backgroundColor: theme.palette.common.white,
                alignItems: 'center',
            },
        }}
        >
            <Stack.Screen name={ADD_LOGGER_SCREENS.ENTER_ASSET_CODE} component={EnterAssetCode} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.SCAN_ASSET_CODE} component={ScanAssetCode} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.CHECK_ASSET_CODE} component={CheckAsset} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.ADD_NEW_ASSET} component={AddNewAsset} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE} component={ScanLoggerCode} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.ENTER_LOGGER_CODE} component={EnterLoggerCode} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.CHECK_LOGGER_CODE} component={CheckLogger} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.PAIRING_CONFIRM} component={PairingConfirm} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.TAKE_PICTURE_INIT} component={TakePictureInit} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.TAKE_PICTURE_CONFIRM} component={TakePictureConfirm} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.PAIRING_SUCCESS} component={PairingSuccess} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.ERROR} component={ErrorScreen} />
        </Stack.Navigator>
    );
};
