import {
    REACT_APP_AUTH_SERVER_URL,
    REACT_APP_REALM,
    REACT_APP_RESOURCE,
    REACT_APP_SECURE_API,
    ENV,
    BUILD_DATE,
} from '@env';

const url = REACT_APP_AUTH_SERVER_URL;
const realm = REACT_APP_REALM;
const clientId = REACT_APP_RESOURCE;
const apiUrl = REACT_APP_SECURE_API;
const envName = ENV;
const buildDate = BUILD_DATE;

export { url, realm, clientId, apiUrl, envName, buildDate };
