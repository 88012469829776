import React from 'react';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { useTranslation } from 'react-i18next';
import { STATUSES, Status } from '../shared-components/Status';
import { Layout } from '../Layout';
import { Container } from '../Layout/Container';

export const ErrorScreen = ({ navigation }) => {
    const { t } = useTranslation();

    return (
        <Layout>
            <Container isPaddingTop>
                <Status status={STATUSES.ERROR} />
            </Container>

            <Container>
                <ButtonsBlock
                    secondaryBtnText={t('COMMON.TRY_AGAIN')}
                    secondaryBtnOnPress={() => navigation.goBack()}
                />
            </Container>
        </Layout>
    );
};
