export const LOGGER_INFO_URL = 'https://logger-info.skycell.ch/';

export const ACCESS_TOKEN = 'accessToken';
export const USER_INFO = 'userInfo';

export const ASSET_TYPE_CODES = {
    CONTAINER: 'CONTAINER',
    PALLET: 'AIRPORT_PALLET',
};

export const INSTANCE_TYPES = {
    ASSET: 'asset',
    LOGGER: 'logger',
};

export const APP_NAME = 'SkyMind';
export const APP_LONG_NAME = 'SkyMind\nAsset Pairing';

export const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'nl', label: 'Dutch' },
];
