import React, {
    createContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Platform, View, Text } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import {
    ResponseType,
    makeRedirectUri,
    useAuthRequest,
    useAutoDiscovery,
    exchangeCodeAsync,
    fetchUserInfoAsync,
    dismiss,
    revokeAsync,
    // TokenResponse,
    TokenTypeHint,
} from 'expo-auth-session';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ACCESS_TOKEN, USER_INFO } from '@src/constants';
import { url, realm, clientId } from '@src/constants/env';

import { useNotification } from '@src/hooks/useNotification';
import {
    StoreContextInterface,
    UserInfo,
    Layout,
} from './dataTypes';

import {
    initialExtendedProfile,
    initialUserInfo,
    initialLayout,
} from './lib';

export const StoreContext = createContext<StoreContextInterface>({
    auth: {
        accessToken: '',
        login: () => {},
        logout: () => {},
        isLogin: false,
    },
    userInfo: initialUserInfo,
    layout: initialLayout,
    setLayout: () => {},
});

WebBrowser.maybeCompleteAuthSession();

export const Store = ({ children }) => {
    const { showError } = useNotification();
    const discovery = useAutoDiscovery(`${url}realms/${realm}`);
    const [accessToken, setAccessToken] = useState('');
    const [refreshToken, setRefreshToken] = useState('');
    const [isLogin, setIsLogin] = useState(false);
    // TODO: fix type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [extendedProfile, setExtendedProfile] = useState<any>(initialExtendedProfile);
    const [layout, setLayout] = useState<Layout>(initialLayout);

    const redirectUri = makeRedirectUri({
        scheme: 'skytrack',
        path: 'Actions',
    });

    const [request, , promptAsync] = useAuthRequest(
        {
            responseType: ResponseType.Code,
            clientId,
            redirectUri,
            scopes: ['openid', 'profile'],
        },
        discovery,
    );

    const login = async () => {
        if (!request) return;
        setIsLogin(true);
        try {
            const codeResponse = await promptAsync();

            if (request && codeResponse?.type === 'success' && discovery) {
                const exchangeCodeData = await exchangeCodeAsync(
                    {
                        clientId,
                        code: codeResponse.params.code,
                        extraParams: request.codeVerifier ? { code_verifier: request.codeVerifier } : undefined,
                        redirectUri,
                    },
                    discovery,
                );

                const { accessToken, refreshToken } = exchangeCodeData;

                setRefreshToken(refreshToken);
                setAccessToken(accessToken);

                const userInfo = await fetchUserInfoAsync({ accessToken }, discovery);

                setExtendedProfile(userInfo);

                const userInfoStorage = JSON.stringify(userInfo);

                if (Platform.OS === 'web') {
                    localStorage.setItem(ACCESS_TOKEN, accessToken);
                    localStorage.setItem(USER_INFO, userInfoStorage);
                } else {
                    AsyncStorage.setItem(ACCESS_TOKEN, accessToken);
                    AsyncStorage.setItem(USER_INFO, userInfoStorage);
                }
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }

        setIsLogin(false);
    };

    const logout = async () => {
        try {
            if (Platform.OS === 'web') {
                localStorage.removeItem(ACCESS_TOKEN);
                localStorage.removeItem(USER_INFO);
            } else {
                await AsyncStorage.removeItem(ACCESS_TOKEN);
                await AsyncStorage.removeItem(USER_INFO);
            }

            await revokeAsync({
                token: refreshToken,
                tokenTypeHint: TokenTypeHint.RefreshToken,
                clientId,
            }, discovery);

            dismiss();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }

        setAccessToken('');
        setExtendedProfile(initialExtendedProfile);
    };

    useEffect(() => {
        if (accessToken && extendedProfile) return;

        setIsLogin(true);
        try {
            if (Platform.OS === 'web') {
                const accessTokenStorage = localStorage.getItem(ACCESS_TOKEN);
                const userInfoStorage = localStorage.getItem(USER_INFO);

                if (accessTokenStorage) {
                    setAccessToken(accessTokenStorage);
                }

                if (userInfoStorage) {
                    setExtendedProfile(JSON.parse(userInfoStorage));
                }
            } else {
                AsyncStorage.getItem(ACCESS_TOKEN).then((accessTokenStorage) => {
                    if (accessTokenStorage) {
                        setAccessToken(accessTokenStorage);
                        // const data = new TokenResponse({ accessToken: accessTokenStorage });
                        // console.log('🚀 ~ AsyncStorage.getItem ~ data:', data);
                        // const fresh = TokenResponse.isTokenFresh(data);
                        // console.log('🚀 ~ useEffect ~ fresh:', fresh);
                    }
                });

                AsyncStorage.getItem(USER_INFO).then((userInfoStorage) => {
                    if (userInfoStorage) {
                        setExtendedProfile(JSON.parse(userInfoStorage));
                    }
                });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }

        setIsLogin(false);
    }, []);

    useEffect(() => {
        setIsLogin(!request); // TODO: fix
    }, [request]);

    const userInfo: UserInfo = useMemo(() => {
        if (!extendedProfile.email) {
            return initialUserInfo;
        }

        return extendedProfile;
    }, [extendedProfile]);

    const contextValue = useMemo(() => ({
        auth: {
            accessToken,
            login,
            logout,
            isLogin,
        },
        userInfo,
        layout,
        setLayout,
    }), [userInfo, accessToken, login, logout, layout, isLogin]);

    if (!url || !realm || !clientId) {
        showError('Error: Missing ENV variables');

        return (
            <View>
                <Text>Error: Missing ENV variables</Text>
            </View>
        );
    }

    return (
        <>
            <StoreContext.Provider value={contextValue}>
                {children}
            </StoreContext.Provider>
            {/* {(!accessToken || result?.type !== 'success')
            && (
                <Login login={login} />
            )}

            {(accessToken || result?.type === 'success')
            && (
                <StoreContext.Provider value={contextValue}>
                    {children}
                </StoreContext.Provider>
            )} */}

            {/* <AuthContext.Provider value={contextValue}>
                {children}
            </AuthContext.Provider> */}

            {/* {
                !isAuthLoading && status === 'SUCCESS' && (
                    <AuthContext.Provider
                        value={{
                            userInfo,
                            token,
                            logout: keycloak ? keycloak.logout : () => {},
                        }}
                    >
                        {children}
                    </AuthContext.Provider>
                )
            } */}
            {/* {
                !isAuthLoading && status === 'FAILURE' && (
                    <StatusInfo
                        status={status}
                        error="An Error happened during login"
                    />
                )
            }
            {
                (status === 'PENDING') && (
                    <StatusInfo
                        status={status}
                        isAuthLoading={isAuthLoading}
                    />
                )
            } */}
        </>
    );
};
