import React, { useCallback, useContext } from 'react';
import { Platform, StyleSheet, Text, View, Image } from 'react-native';
import { useTranslation } from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { font, theme } from '@src/styles';
import { StoreContext } from '@src/Contexts/StoreContext';
import { languageOptions } from '@src/constants';
import { Select } from '../shared-components/Select';

const styles = StyleSheet.create({
    dropdownRowTxtStyle: {
        ...font.normal,
        color: theme.palette.common.black,
        fontSize: 14,
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        textAlign: 'left',
    },
    dropdownRowWrapper: {
        alignItems: 'center',
        flexDirection: 'row',
    },
    img: {
        height: 20,
        marginRight: 10,
        width: 20,
    },
    margin: {
        marginLeft: 17,
        marginTop: 5,
    },
    selectWrapper: {
        width: '100%',
    },
    wrapper: {
        marginBottom: 20,
    },
});

const countryFlags = {
    en: require('@assets/images/flags/en.png'),
    fr: require('@assets/images/flags/fr.png'),
    de: require('@assets/images/flags/de.png'),
    nl: require('@assets/images/flags/nl.png'),
};

export const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const { layout, setLayout } = useContext(StoreContext);

    const restart = () => {
        setLayout({ ...layout, isRestarting: true });
        setTimeout(() => {
            setLayout({ ...layout, isRestarting: false });
        }, 500);
    };

    const setLanguageStorage = async (language) => {
        if (Platform.OS === 'web') {
            localStorage.setItem('language', language);
        } else {
            await AsyncStorage.setItem('language', language);
        }

        restart();
    };

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setLanguageStorage(language);
    };

    const getDefaultValue = () => {
        return languageOptions.find((option) => option.value === i18n.language);
    };

    const getRow = useCallback(({ item, customStylesWrapper = {} }) => {
        return (
            <View style={[styles.dropdownRowWrapper, customStylesWrapper]}>
                <Image style={styles.img} source={countryFlags[item.value]} />
                <Text style={styles.dropdownRowTxtStyle}>{item.label}</Text>
            </View>
        );
    }, []);

    return (
        <View style={styles.wrapper}>
            <Select
                defaultValue={getDefaultValue()}
                data={languageOptions}
                defaultButtonText="SELECT"
                onSelect={(selectedItem) => changeLanguage(selectedItem.value)}
                buttonTextAfterSelection={(item) => getRow({ item })}
                rowTextForSelection={(item) => item.label}
                height={130}
                renderCustomizedRowChild={(item) => getRow({ item, customStylesWrapper: styles.margin })}
                wrapperStyle={styles.selectWrapper}
            />
        </View>
    );
};
