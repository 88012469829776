import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { font, theme } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';

export const Title = ({ children, style = {} }) => {
    const { isLandscape, hp } = useScreen();

    const styles = StyleSheet.create({
        title: {
            ...font.normal,
            color: theme.palette.common.black,
            fontSize: 24,
            letterSpacing: 0.18,
            lineHeight: 26,
            marginBottom: 12,
            textAlign: 'center',
            ...(isLandscape && {
                marginBottom: hp('3%'),
                fontSize: hp('3%'),
                lineHeight: hp('3.2%'),
            }),
        },
    });

    return (
        <Text style={[styles.title, style]}>
            {children}
        </Text>
    );
};
