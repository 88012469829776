import { Platform } from 'react-native';
import { APP_NAME, INSTANCE_TYPES, LOGGER_INFO_URL } from '@src/constants';
import { STATUSES } from '@src/components/shared-components/Status';
import { envName } from '@src/constants/env';

type Params = {
  type: 'asset' | 'logger',
  data: {
    loggerNumber?: string,
    assetNumber?: string,
  };
  setStatus: (status: string) => void,
  };

export const getStatus = ({ type, data = {}, setStatus }: Params) => {
    if (type === INSTANCE_TYPES.ASSET) {
        const { assetNumber, loggerNumber } = data;

        if (!assetNumber) {
            setStatus(STATUSES.NOT_FOUND);
            return STATUSES.NOT_FOUND;
        } else if (assetNumber && loggerNumber) {
            setStatus(STATUSES.PAIRED);
            return STATUSES.PAIRED;
        } else if (assetNumber && !loggerNumber) {
            setStatus(STATUSES.IDENTIFIED);
            return STATUSES.IDENTIFIED;
        }
    } else if (type === INSTANCE_TYPES.LOGGER) {
        const { loggerNumber, assetNumber } = data;

        if (!loggerNumber) {
            setStatus(STATUSES.NOT_FOUND);
            return STATUSES.NOT_FOUND;
        } else if (loggerNumber && assetNumber) {
            setStatus(STATUSES.PAIRED);
            return STATUSES.PAIRED;
        } else if (loggerNumber && !assetNumber) {
            setStatus(STATUSES.IDENTIFIED);
            return STATUSES.IDENTIFIED;
        }
    } else if (data) {
        const { assetNumber, loggerNumber } = data;

        if (!assetNumber && !loggerNumber) {
            setStatus(STATUSES.NOT_FOUND);
            return STATUSES.NOT_FOUND;
        }
    }
};

const getEnvironment = () => {
    if (Platform.OS !== 'web') return null;

    const url = document.location;

    if (url.host.includes('test')) {
        return ' (TEST)';
    }
    if (url.host.includes('dev')) {
        return ' (DEV)';
    }
    if (url.host.includes('pre')) {
        return ' (PRE)';
    }
    if (url.host.includes('demo')) {
        return ' (DEMO)';
    }
    if (url.host.includes('poc')) {
        return ' (POC)';
    }
    return null;
};

export const getPageTitle = () => {
    const title = APP_NAME;

    const suffix = getEnvironment();

    if (suffix) {
        return `${title}${suffix}`;
    }

    return title;
};

export const getLoggerNumberWithoutPrefix = (loggerNumberString?: string) => {
    const numberWithoutPrefix = loggerNumberString.replace(LOGGER_INFO_URL, '');

    return numberWithoutPrefix;
};

export const dataURLtoFile = async (uri) => {
    const response = await fetch(uri);
    const blob = await response.blob();

    return blob;
};

export const checkIsDev = () => {
    if (Platform.OS === 'web') {
        const url = document.location.host.toLowerCase();

        return url.includes('localhost') || url.includes('dev') || url.includes('test');
    }

    return envName === 'DEV';
};
