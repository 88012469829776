import React, { useContext, useEffect, useState } from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { useTranslation } from 'react-i18next';
import { Input } from '@src/components/shared-components/Input';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { Title } from '@src/components/Title';
import { useScreen } from '@src/hooks/useScreen';
import { font } from '@src/styles';
import { ADD_LOGGER_SCREENS } from '../constant';
import { getEnterCodeScreenStyles } from '../styles';

export const EnterAssetCode = ({ navigation }) => {
    const { t } = useTranslation();
    const { isLandscape, wp, hp } = useScreen();
    const { setAsset } = useContext(AddLoggerContext);
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const onConfirm = () => {
        if (!code) {
            setError(t('COMMON.FIELD_REQUIRED'));
            return;
        }
        setAsset(curAsset => ({ ...curAsset, number: code }));
        navigation.navigate(ADD_LOGGER_SCREENS.CHECK_ASSET_CODE, { fromScreen: ADD_LOGGER_SCREENS.ENTER_ASSET_CODE });
    };

    const onScanCode = () => {
        // TODO: fix this
        // navigation.navigate(ADD_LOGGER_SCREENS.SCAN_ASSET_CODE);
        navigation.reset({ index: 0, routes: [{ name: ADD_LOGGER_SCREENS.SCAN_ASSET_CODE }] });
    };

    useEffect(() => {
        setError('');
    }, [code]);

    const onChangeText = (value) => setCode(value);

    const sharedStyles = getEnterCodeScreenStyles({ hp });

    const styles = StyleSheet.create({
        exampleImage: {
            height: hp('15%'),
            width: wp('48%'),
            ...(isLandscape && {
                height: hp('22%'),
                width: wp('22%'),
            }),
        },
        exampleImageText: {
            ...font.normal,
            fontSize: 16,
            letterSpacing: 0.5,
            lineHeight: 18,
            ...(isLandscape && {
                fontSize: hp('2.5%'),
                lineHeight: hp('2.8%'),
            }),
        },
        exampleImageWrapper: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        exampleImageWrapperFirst: {
            marginBottom: hp('3.4%'),
        },
    });

    return (
        <Layout>
            <Container>
                {!isLandscape && (
                    <>
                        <Title>
                            {t('ADD_LOGGER.ENTER_ASSET_CODE')}
                        </Title>
                        <View style={sharedStyles.inputWrapper}>
                            <Input
                                value={code}
                                onChangeText={onChangeText}
                                placeholder="XXX 9999(9) XX"
                                placeholderTextColor="#D7D7D7"
                                error={error}
                            />
                        </View>
                    </>
                )}
                <View style={[styles.exampleImageWrapper, styles.exampleImageWrapperFirst]}>
                    <Text style={styles.exampleImageText}>{t('COMMON.PALLET')}</Text>
                    <Image
                        style={styles.exampleImage}
                        source={require('@assets/images/enter_asset_code_pallet.png')}
                        resizeMode="contain"
                    />
                </View>

                <View style={styles.exampleImageWrapper}>
                    <Text style={styles.exampleImageText}>{t('COMMON.CONTAINER')}</Text>
                    <Image
                        style={styles.exampleImage}
                        source={require('@assets/images/enter_asset_code_container.png')}
                        resizeMode="contain"
                    />
                </View>
            </Container>

            <Container>
                {isLandscape && (
                    <>
                        <Title>
                            {t('ADD_LOGGER.ENTER_ASSET_CODE')}
                        </Title>
                        <View style={sharedStyles.inputWrapper}>
                            <Input
                                value={code}
                                onChangeText={onChangeText}
                                placeholder="XXX 9999(9) XX"
                                placeholderTextColor="#D7D7D7"
                                error={error}
                            />
                        </View>
                    </>
                )}

                <ButtonsBlock
                    primaryBtnText={t('COMMON.CONFIRM')}
                    primaryBtnOnPress={onConfirm}
                    secondaryBtnText={t('COMMON.SCAN_CODE')}
                    secondaryBtnOnPress={onScanCode}
                />
            </Container>
        </Layout>
    );
};
