import React, { useContext, useState } from 'react';
import { View, Image, ActivityIndicator, StyleSheet } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { useTranslation } from 'react-i18next';
import { paddingLeft, paddingRight, theme } from '@src/styles';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { ASSET_TYPE_CODES } from '@src/constants';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { Pair } from '@src/components/shared-components/Status/Pair';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { Title } from '@src/components/Title';
import { useScreen } from '@src/hooks/useScreen';
import { checkIsDev } from '@src/lib';
import { getEnterCodeScreenStyles } from '../styles';
import { ADD_LOGGER_SCREENS } from '../constant';

const isPictureTakingShown = checkIsDev();

export const PairingConfirm = ({ navigation }) => {
    const { t } = useTranslation();
    const { isLandscape, wp, hp } = useScreen();
    const { asset, logger, stepper } = useContext(AddLoggerContext);
    const { loggerPairing } = useBackendEndpoints().requests;
    const [isLoading, setIsLoading] = useState(false);

    const onConfirm = async () => {
        setIsLoading(true);
        const result = await loggerPairing(asset.assetId, logger.number);

        if (result && result.status === 201) {
            if (isPictureTakingShown) {
                navigation.navigate(ADD_LOGGER_SCREENS.TAKE_PICTURE_INIT);
            } else {
                navigation.navigate(ADD_LOGGER_SCREENS.PAIRING_SUCCESS);
            }
        }

        setIsLoading(false);
    };

    const onDismiss = () => {
        stepper.setCurStep(1);
        navigation.navigate(ADD_LOGGER_SCREENS.ENTER_ASSET_CODE);
    };

    const sharedStyles = getEnterCodeScreenStyles({ hp });

    const styles = StyleSheet.create({
        image: {
            height: hp('26%'),
            width: wp('100%') - paddingRight - paddingLeft,
            ...(isLandscape && {
                height: hp('35%'),
                width: wp('34%'),
            }),
        },
        imageWrapper: {
            marginTop: 38,
            ...(isLandscape && {
                marginTop: hp('3%'),
            }),
        },
        pairWrapper: {
            marginTop: 33,
            ...(isLandscape && {
                marginTop: 0,
                marginBottom: hp('5.6%'),
            }),
        },
    });

    return (
        <Layout verticalAlign="center">
            <Container>
                <Title>
                    {t(asset.assetTypeCode === ASSET_TYPE_CODES.CONTAINER
                        ? 'ADD_LOGGER.ATTACH_LOGGER_INSIDE_CONTAINER'
                        : 'ADD_LOGGER.ATTACH_LOGGER_TO_PALLET')}
                </Title>

                {!isLandscape && (
                    <Pair
                        style={styles.pairWrapper}
                        loggerNumber={logger.number}
                        assetNumber={asset.number}
                        assetTypeCode={asset.assetTypeCode}
                        deviderTextCode="ADD_LOGGER.WILL_BE_PAIRED_WITH"
                    />
                )}

                <View style={[sharedStyles.imageWrapper, styles.imageWrapper]}>
                    {asset.assetTypeCode === ASSET_TYPE_CODES.CONTAINER && (
                        <Image
                            style={styles.image}
                            source={require('@assets/images/place_logger_inside_container.png')}
                            resizeMode="contain"
                        />
                    )}
                    {asset.assetTypeCode === ASSET_TYPE_CODES.PALLET && (
                        <Image
                            style={styles.image}
                            source={require('@assets/images/place_logger_to_pallet.png')}
                            resizeMode="contain"
                        />
                    )}
                </View>
            </Container>

            <Container>
                {isLandscape && (
                    <Pair
                        style={styles.pairWrapper}
                        loggerNumber={logger.number}
                        assetNumber={asset.number}
                        assetTypeCode={asset.assetTypeCode}
                        deviderTextCode="ADD_LOGGER.WILL_BE_PAIRED_WITH"
                    />
                )}

                <ButtonsBlock
                    primaryBtnText={isLoading
                        ? <ActivityIndicator size="small" color={theme.palette.common.white} />
                        : t('COMMON.CONFIRM')}
                    isPrimaryBtnDisabled={isLoading}
                    primaryBtnOnPress={onConfirm}
                    secondaryBtnText={t('COMMON.CANCEL')}
                    secondaryBtnOnPress={onDismiss}
                />
            </Container>
        </Layout>
    );
};
