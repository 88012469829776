import React, { useContext, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { RemoveLoggerContext } from '@src/RemoveLogger/RemoveLoggerContext';
import { ASSET_TYPE_CODES, INSTANCE_TYPES } from '@src/constants';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { getStatus } from '@src/lib';
import { STATUSES, Status } from '@src/components/shared-components/Status';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { useTranslation } from 'react-i18next';
import { LayoutRemoveLogger } from '@src/components/Layout';
import { useNotification } from '@src/hooks/useNotification';
import { Pair } from '@src/components/shared-components/Status/Pair';
import { Container } from '@src/components/Layout/Container';
import { Title } from '@src/components/Title';
import { Number } from '@src/components/Number';
import { SpinnerScreen } from '@src/components/SpinnerScreen';
import { useScreen } from '@src/hooks/useScreen';
import { paddingLeft, paddingRight } from '@src/styles';
import { REMOVE_LOGGER_SCREENS } from '../constant';
import { Description } from './Description';
import { ConfirmModal } from './ConfirmModal';

const backScreen = REMOVE_LOGGER_SCREENS.ENTER_ASSET_OR_LOGGER_CODE;

export const CheckCode = ({ navigation, route }) => {
    const { type, scannedNumber, fromScreen } = route.params || {};
    const { isLandscape, wp, hp } = useScreen();
    const { t } = useTranslation();
    const { showSuccess } = useNotification();
    const { logger, asset, setLogger, setAsset, stepper } = useContext(RemoveLoggerContext);
    const { checkPairingByLogger, checkPairingByAsset } = useBackendEndpoints().requests;
    const [status, setStatus] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const instance = useMemo(() => (type === INSTANCE_TYPES.ASSET ? asset : logger), [type, asset, logger]);
    const setInstance = useMemo(
        () => (type === INSTANCE_TYPES.ASSET ? setAsset : setLogger),
        [type, setAsset, setLogger],
    );
    const checkPairing = useMemo(
        () => (type === INSTANCE_TYPES.ASSET ? checkPairingByAsset : checkPairingByLogger),
        [type, checkPairingByAsset, checkPairingByLogger],
    );

    const checkInstance = async () => {
        if (!instance.number) {
            setStatus(STATUSES.NOT_FOUND);
            return;
        }

        const result = await checkPairing(instance.number);

        if (result && result.data) {
            const { data } = result;

            setInstance({ ...instance, ...data });
            const newStatus = getStatus({ type, data, setStatus });

            if (newStatus === STATUSES.PAIRED) {
                showSuccess(t('REMOVE_LOGGER.SUCCESSFULLY_IDETIFIED', { number: instance.number }));
            }
        } else {
            setStatus(STATUSES.ERROR);
        }
    };

    useEffect(() => {
        checkInstance();
    }, [type]);

    const tryAgain = () => {
        stepper.prevStep();
        if (fromScreen !== REMOVE_LOGGER_SCREENS.SCAN_CODE) {
            navigation.goBack();
            return;
        }

        navigation.reset({ index: 0, routes: [{ name: fromScreen || backScreen }] });
    };

    const openModal = () => {
        setIsModalVisible(true);
    };

    const onModalClose = () => {
        setIsModalVisible(false);
    };

    const styles = StyleSheet.create({
        image: {
            height: hp('26%'),
            width: wp('100%') - paddingRight - paddingLeft,
            ...(isLandscape && {
                height: hp('35%'),
                width: wp('34%'),
            }),
        },
        imageWrapper: {
            alignItems: 'center',
            display: 'flex',
            marginTop: 23,
            ...(isLandscape && {
                marginTop: 0,
            }),
        },
        notFoundWrapper: {
            ...(isLandscape && {
                paddingTop: hp('7.3%'),
            }),
        },
        pairWrapper: {
            marginTop: 27,
            ...(isLandscape && {
                marginTop: 0,
                marginBottom: hp('5.1%'),
            }),
        },
    });

    if (status === STATUSES.IDENTIFIED) {
        return (
            <LayoutRemoveLogger verticalAlign="center">
                <Container isPaddingTop>
                    <Status status={STATUSES.IDENTIFIED} assetTypeCode={asset.assetTypeCode} instanceType={type} />
                    <Number>{instance.number}</Number>
                    {!isLandscape && (<Description assetTypeCode={asset.assetTypeCode} instanceType={type} />)}
                </Container>

                <Container>
                    {isLandscape && (<Description assetTypeCode={asset.assetTypeCode} instanceType={type} />)}
                    <ButtonsBlock
                        secondaryBtnText={t('COMMON.BACK')}
                        secondaryBtnOnPress={tryAgain}
                    />
                </Container>
            </LayoutRemoveLogger>
        );
    }

    if (status === STATUSES.NOT_FOUND) {
        return (
            <LayoutRemoveLogger verticalAlign="center" wrapperStyle={styles.notFoundWrapper}>
                <Container isPaddingTop>
                    <Status status={STATUSES.NOT_FOUND} instanceType={type} />
                    <Number>{instance.number || scannedNumber}</Number>
                </Container>

                <Container>
                    <ButtonsBlock
                        primaryBtnText={t('COMMON.TRY_AGAIN')}
                        primaryBtnOnPress={tryAgain}
                    />
                </Container>
            </LayoutRemoveLogger>
        );
    }

    if (status === STATUSES.PAIRED) {
        return (
            <LayoutRemoveLogger isWrapperNoPadding={isLandscape}>
                <Container>
                    <Title>
                        {instance.assetTypeCode === ASSET_TYPE_CODES.PALLET
                            ? t('REMOVE_LOGGER.CONFIRM_REMOVE_TITLE_PALLET')
                            : t('REMOVE_LOGGER.CONFIRM_REMOVE_TITLE_CONTAINER')}
                    </Title>

                    {!isLandscape && (
                        <View style={styles.pairWrapper}>
                            <Pair
                                assetNumber={instance.assetNumber}
                                loggerNumber={instance.loggerNumber}
                                assetTypeCode={asset.assetTypeCode}
                                deviderTextCode="REMOVE_LOGGER.NO_LONGER"
                            />
                        </View>
                    )}

                    <View style={styles.imageWrapper}>
                        {instance.assetTypeCode === ASSET_TYPE_CODES.CONTAINER && (
                            <Image
                                style={styles.image}
                                source={require('@assets/images/remove_logger_container.png')}
                                resizeMode="contain"
                            />
                        )}
                        {instance.assetTypeCode === ASSET_TYPE_CODES.PALLET && (
                            <Image
                                style={styles.image}
                                source={require('@assets/images/remove_logger_pallet.png')}
                                resizeMode="contain"
                            />
                        )}
                    </View>

                    <ConfirmModal
                        isVisible={isModalVisible}
                        onClose={onModalClose}
                        type={type}
                        navigation={navigation}
                    />
                </Container>

                <Container>
                    {isLandscape && (
                        <View style={styles.pairWrapper}>
                            <Pair
                                assetNumber={instance.assetNumber}
                                loggerNumber={instance.loggerNumber}
                                assetTypeCode={asset.assetTypeCode}
                                deviderTextCode="REMOVE_LOGGER.NO_LONGER"
                            />
                        </View>
                    )}
                    <ButtonsBlock
                        primaryBtnText={t('COMMON.REMOVE')}
                        secondaryBtnText={t('COMMON.CANCEL')}
                        primaryBtnOnPress={openModal}
                        secondaryBtnOnPress={tryAgain}
                    />
                </Container>
            </LayoutRemoveLogger>
        );
    }

    if (status === STATUSES.ERROR) {
        return (
            <LayoutRemoveLogger>
                <Container>
                    <Status status={STATUSES.ERROR} />
                </Container>

                <Container>
                    <ButtonsBlock
                        secondaryBtnText={t('COMMON.TRY_AGAIN')}
                        secondaryBtnOnPress={tryAgain}
                    />
                </Container>
            </LayoutRemoveLogger>
        );
    }

    return (
        <SpinnerScreen />
    );
};
