import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { SideBar } from '@src/components/Header/SideBar';
import { Logo } from '@src/components/Header/Logo';
import { useTranslation } from 'react-i18next';
import { useContext, useMemo } from 'react';
import { StoreContext } from '@src/Contexts/StoreContext';
import { Home } from '@src/Home';
import { Login } from '@src/Login';
import { AddLoggerPath } from '@src/AddLogger/AddLoggerPath';
import { RemoveLoggerPath } from '@src/RemoveLogger/RemoveLoggerPath';
import { font, theme } from '@src/styles';
import { NoAccess } from '@src/NoAccess';
import { getPageTitle } from '@src/lib';
import { HeaderRight } from '@src/components/Header/HeaderRight';
import { SpinnerScreen } from '@src/components/SpinnerScreen';
// import { DevInfo } from '@src/DevInfo';
import { useScreen } from '@src/hooks/useScreen';
import { SCREEN_NAMES } from './constant';

const Drawer = createDrawerNavigator();
// const env = 'development';

function MyDrawer() {
    const { t } = useTranslation();
    const { isLandscape, wp, hp } = useScreen();
    const { auth, layout, userInfo } = useContext(StoreContext);
    const { accessToken, isLogin } = auth;
    const hasRole = userInfo?.groups?.includes('ASSET_PAIRING');

    const screens = useMemo(() => {
        if (isLogin) {
            return (
                <Drawer.Screen
                    name={SCREEN_NAMES.NO_ACCESS}
                    component={SpinnerScreen}
                    options={{ title: getPageTitle() }}
                />
            );
        } else if (!accessToken) {
            return (
                <Drawer.Screen
                    name={SCREEN_NAMES.LOGIN}
                    component={Login}
                    options={{ title: getPageTitle() }}
                />
            );
        } else if (accessToken && userInfo && !hasRole) {
            return (
                <Drawer.Screen
                    name={SCREEN_NAMES.NO_ACCESS}
                    component={NoAccess}
                    options={{ title: getPageTitle() }}
                />
            );
        } else if (accessToken && hasRole) {
            return (
                <>
                    <Drawer.Screen
                        name={SCREEN_NAMES.ACTIONS}
                        component={Home}
                        options={{
                            title: getPageTitle(),
                            headerTitle: t(`MENU_ITEMS.${SCREEN_NAMES.ACTIONS}`),
                        }}
                    />
                    <Drawer.Screen
                        name={SCREEN_NAMES.ADD_LOGGER}
                        component={AddLoggerPath}
                        options={{
                            headerTitle: t(`MENU_ITEMS.${SCREEN_NAMES.ADD_LOGGER}`),
                        }}
                    />
                    <Drawer.Screen
                        name={SCREEN_NAMES.REMOVE_LOGGER}
                        component={RemoveLoggerPath}
                        options={{
                            headerTitle: t(`MENU_ITEMS.${SCREEN_NAMES.REMOVE_LOGGER}`),
                        }}
                    />
                    {/* {env === 'development' && (
                        <Drawer.Screen
                            name={SCREEN_NAMES.DEV_INFO}
                            component={DevInfo}
                            options={{
                                headerTitle: t(`MENU_ITEMS.${SCREEN_NAMES.DEV_INFO}`),
                            }}
                        />
                    )} */}
                </>
            );
        }
    }, [accessToken, userInfo, hasRole, isLogin]);

    return (
        <Drawer.Navigator
            screenOptions={{
                headerShown: !!accessToken && !layout.isFullScreen,
                headerLeft: Logo,
                headerRight: HeaderRight,
                headerStyle: {
                    backgroundColor: theme.palette.primary.darkBlue,
                    ...(isLandscape && {
                        height: hp('12%'),
                    }),
                },
                headerTitleStyle: {
                    ...font.normal,
                    color: theme.palette.common.white,
                    fontSize: 18,
                    lineHeight: 20,
                    height: 24,
                    ...(isLandscape && {
                        fontSize: hp('3.5%'),
                        lineHeight: hp('3.5%'),
                        height: hp('4%'),
                    }),
                },
                drawerType: 'front',
                headerTitleAlign: 'center',
                drawerStyle: {
                    width: wp('85%'),
                    ...(isLandscape && {
                        width: wp('30%'),
                    }),
                },
                sceneContainerStyle: {
                    backgroundColor: theme.palette.common.white,
                },
            }}
            drawerContent={SideBar}
        >
            {screens}
        </Drawer.Navigator>
    );
}

export const Root = () => {
    const { layout } = useContext(StoreContext);

    return (
        <NavigationContainer>
            {layout.isRestarting ? <SpinnerScreen /> : <MyDrawer />}
        </NavigationContainer>
    );
};
