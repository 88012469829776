import React, { useState } from 'react';
import { TextInput, StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { font, theme } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';
import { MaterialIcons } from '@expo/vector-icons';

export const Input = ({
    label = '',
    error = '',
    style = {},
    styleWrapper = {},
    disabled = false,
    onChangeText,
    ...props
}) => {
    const { isLandscape, hp } = useScreen();
    const [isFocused, setIsFocused] = useState(false);

    const styles = StyleSheet.create({
        disabled: {
            backgroundColor: theme.palette.secondary[100],
            borderColor: theme.palette.secondary[100],
        },
        error: {
            ...font.normal,
            color: theme.palette.error.main,
            fontSize: 16,
            letterSpacing: 0.15,
            lineHeight: 24,
            marginTop: 16,
            ...(isLandscape && {
                fontSize: hp('2.1%'),
                lineHeight: hp('2.5%'),
                letterSpacing: 0.24,
            }),
        },
        focused: {
            borderColor: theme.palette.common.black,
        },
        icon: {
            position: 'absolute',
            right: 10,
            top: 10,
            ...(isLandscape && {
                top: hp('2%'),
            }),
        },
        input: {
            ...font.normal,
            borderColor: theme.palette.common.secondaryGray,
            borderRadius: 4,
            borderWidth: 1,
            fontSize: 16,
            height: 55,
            letterSpacing: 0.15,
            padding: 15,
            ...(isLandscape && {
                ...font.medium,
                height: hp('8.0%'),
                fontSize: hp('2.1%'),
            }),
        },
        inputError: {
            borderColor: theme.palette.error.main,
        },
        inputWrapper: {
            position: 'relative',
        },
        label: {
            ...font.medium,
            color: theme.palette.common.black,
            fontSize: 14,
            letterSpacing: 0.1,
            lineHeight: 24,
            marginBottom: 5,
            ...(isLandscape && {
                ...font.normal,
                height: hp('2.5%'),
                fontSize: hp('2.5%'),
                lineHeight: hp('2.5%'),
                marginBottom: hp('2%'),
            }),
        },
    });

    return (
        <View style={styleWrapper}>
            {label && <Text style={styles.label}>{label}</Text>}
            <View style={styles.inputWrapper}>
                <TextInput
                    style={[
                        styles.input,
                        isFocused && styles.focused,
                        error && styles.inputError,
                        disabled && styles.disabled,
                        style,
                    ]}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholderTextColor={theme.palette.common.secondaryGray}
                    editable={!disabled}
                    autoCapitalize="characters"
                    autoComplete="off"
                    onChangeText={onChangeText}
                    {...props}
                />
                <TouchableOpacity
                    onPress={() => onChangeText('')}
                    style={styles.icon}
                >
                    <MaterialIcons
                        name="clear"
                        size={isLandscape ? hp('4%') : 35}
                        color={theme.palette.common.secondaryGray}
                    />
                </TouchableOpacity>
            </View>
            {error && <Text style={styles.error}>{error}</Text>}
        </View>
    );
};
